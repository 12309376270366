@import url(https://fonts.googleapis.com/css?family=Lato|Roboto&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Coolors Exported Palette - coolors.co/00131c-053d5b-f9f9ed-ff5e07-dbd0c9 */
/* HSL */
/* RGB */
/* Coolors Exported Palette - coolors.co/00131c-053d5b-f9f9ed-ff5e07-dbd0c9 */
/* HSL */
/* RGB */
.About {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ededed;
  margin: 7rem auto !important;
  padding: 2rem;
  border: 0.5px solid #e7e7e7; }
  @media (max-width: 992px) {
    .About {
      margin: 4rem !important; } }
  @media (max-width: 768px) {
    .About {
      margin: 4rem auto 0 !important;
      padding-bottom: 0; } }
  .About .about-bio-row {
    height: auto;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 0.5rem; }
    @media (max-width: 768px) {
      .About .about-bio-row {
        margin: 2vh auto;
        padding: 0; } }
    @media (max-width: 992px) {
      .About .about-bio-row {
        margin: 0; } }
    .About .about-bio-row .about-bio-col {
      display: flex;
      flex-direction: column;
      padding: 1rem; }
      @media (max-width: 400px) {
        .About .about-bio-row .about-bio-col {
          padding: 0 !important; } }
      .About .about-bio-row .about-bio-col .about-info-wrapper {
        display: flex;
        flex-flow: column nowrap;
        font-size: 1.1rem;
        align-items: center; }
  .About .about-social-row {
    height: auto;
    width: 60%;
    display: flex;
    flex-direction: row;
    padding: 1rem;
    margin: 2rem 0;
    background-color: #f0f0f0; }
    @media (max-width: 992px) {
      .About .about-social-row {
        flex-direction: column;
        padding: 1rem; } }
    .About .about-social-row .col {
      padding: 0; }
      .About .about-social-row .col a {
        width: 100%; }
    .About .about-social-row .about-social-media-link {
      border-radius: 1px;
      background-color: #a0c5da;
      border: 1px solid lightgray;
      padding: 1rem;
      letter-spacing: 1px; }
    .About .about-social-row .about-social-media-link:hover {
      background-color: #7bafcc; }

.Footer {
  min-height: 5rem;
  width: 100%;
  display: flex;
  background-color: #525b64;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  @media (max-width: 820px) {
    .Footer {
      position: relative !important; } }
  .Footer p {
    color: #f9f9ed;
    margin: 0.5rem;
    padding: 0 1rem; }

.Services {
  background-color: #f9f9ed; }

/* Coolors Exported Palette - coolors.co/00131c-053d5b-f9f9ed-ff5e07-dbd0c9 */
/* HSL */
/* RGB */
.Contact {
  display: flex;
  flex-direction: column;
  margin: 7rem auto 7rem;
  padding: 1.6rem;
  width: 76vw;
  height: auto;
  background-color: whitesmoke; }
  @media (max-width: 449px) {
    .Contact {
      padding: 0.25rem;
      width: 98vw;
      margin: 6rem auto 2rem; } }
  @media (min-width: 960px) {
    .Contact {
      max-width: 48vw; } }
  @media (min-width: 1300px) {
    .Contact {
      max-width: 33vw; } }
  .Contact h1 {
    margin: 1.6rem auto;
    font-weight: bold;
    letter-spacing: 2px; }
    @media (max-width: 768px) {
      .Contact h1 {
        margin: 1rem auto; } }
  .Contact .form-group {
    padding: 0;
    margin: 1rem 0;
    flex-flow: nowrap; }
  .Contact label {
    font-weight: 600;
    letter-spacing: 0.5px;
    padding: 0;
    text-align: right; }
    @media (max-width: 500px) {
      .Contact label {
        font-size: 0.8rem; } }
  .Contact input {
    font-size: 0.9rem; }
    @media (max-width: 500px) {
      .Contact input {
        font-size: 0.8rem; } }
  @media (max-width: 500px) {
    .Contact textarea {
      font-size: 0.8rem; } }
  .Contact .input-error {
    border: 1px solid red; }
  .Contact .input-success {
    border: 1px solid green; }
  .Contact .input-warning {
    border: 1px solid orange; }
  .Contact button {
    width: 100%;
    margin: 1rem auto;
    padding: 1rem;
    font-size: 1.3rem;
    letter-spacing: 1px;
    background-color: #ff955c;
    border: 0;
    border-radius: 2px; }
    @media (max-width: 768px) {
      .Contact button {
        margin: 0 0 1rem; } }
  .Contact button:hover {
    background-color: #f57d3d;
    letter-spacing: 2px; }
  .Contact div.required-field-notice {
    display: flex; }
    .Contact div.required-field-notice small {
      margin: 0.2rem auto; }

/* Coolors Exported Palette - coolors.co/00131c-053d5b-f9f9ed-ff5e07-dbd0c9 */
/* HSL */
/* RGB */
.Navbar {
  min-height: 4rem;
  font-family: 'Lato', sans-serif;
  letter-spacing: 1px;
  background-color: #38718f !important; }
  .Navbar .navbar-brand {
    font-size: 1.7rem;
    font-weight: 500;
    letter-spacing: 1px;
    color: #f9f9ec !important; }
  .Navbar .navbar-brand:hover {
    color: #ff955c !important; }
  .Navbar .nav-link {
    color: white !important;
    margin: 0 0.5rem; }
  .Navbar .nav-link:hover {
    color: #f2d1c0 !important; }
  @media (max-width: 420px) {
    .Navbar a.navbar-brand {
      font-size: 1.5rem; } }

/* Coolors Exported Palette - coolors.co/00131c-053d5b-f9f9ed-ff5e07-dbd0c9 */
/* HSL */
/* RGB */
.Certifications {
  margin: 5rem auto; }
  @media (max-width: 450px) {
    .Certifications {
      margin-bottom: 0; } }
  .Certifications .certifications-row {
    display: flex;
    justify-content: center; }
    .Certifications .certifications-row .certifications-col {
      margin: 2rem;
      padding: 1rem;
      background-color: whitesmoke; }
      @media (max-width: 768px) {
        .Certifications .certifications-row .certifications-col {
          margin: 1rem;
          padding: 1rem; } }
      .Certifications .certifications-row .certifications-col h2 {
        letter-spacing: 1px;
        font-weight: bold;
        color: #00111a;
        margin: 2rem 0;
        text-align: center; }
      .Certifications .certifications-row .certifications-col ul {
        margin: 2rem 0; }
        @media (max-width: 470px) {
          .Certifications .certifications-row .certifications-col ul {
            padding-left: 20px;
            margin: 1rem 0; } }
        .Certifications .certifications-row .certifications-col ul li {
          margin: 1rem;
          font-size: 1.1rem;
          line-height: 1.35; }
          .Certifications .certifications-row .certifications-col ul li a {
            color: #0793df; }
          .Certifications .certifications-row .certifications-col ul li small {
            color: gray; }

.Portfolio {
  height: auto;
  margin: 4rem auto !important; }
  @media (max-width: 450px) {
    .Portfolio {
      margin-bottom: 1rem; } }
  .Portfolio .portfolio-row {
    display: flex;
    justify-content: center;
    width: 100% !important;
    margin: 2rem auto; }
    @media (max-width: 768px) {
      .Portfolio .portfolio-row {
        margin: 1rem 0; } }
    .Portfolio .portfolio-row .portfolio-col {
      margin: 1rem;
      padding: 2rem;
      background-color: whitesmoke; }
      @media (max-width: 400px) {
        .Portfolio .portfolio-row .portfolio-col {
          margin: 0;
          padding: 0; } }
      .Portfolio .portfolio-row .portfolio-col h2 {
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
        margin: 1rem 0 2rem; }
      .Portfolio .portfolio-row .portfolio-col h4 {
        font-weight: 500; }

/* Coolors Exported Palette - coolors.co/00131c-053d5b-f9f9ed-ff5e07-dbd0c9 */
/* HSL */
/* RGB */
.Solutions {
  margin: 4rem auto; }
  @media (max-width: 450px) {
    .Solutions {
      margin-bottom: 1rem; } }
  .Solutions .solutions-row {
    display: flex;
    justify-content: center;
    width: 100% !important;
    margin: 2rem auto; }
    @media (max-width: 768px) {
      .Solutions .solutions-row {
        margin: 1rem 0; } }
    .Solutions .solutions-row .solutions-col {
      margin: 1rem;
      padding: 2rem;
      background-color: whitesmoke; }
      @media (max-width: 768px) {
        .Solutions .solutions-row .solutions-col {
          margin: 0.5rem;
          padding: 2rem 1rem; } }
      .Solutions .solutions-row .solutions-col h2 {
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
        margin: 1rem 0 2rem; }
      .Solutions .solutions-row .solutions-col h4 {
        font-weight: 500; }
    .Solutions .solutions-row div.solutions-contact-link-wrapper {
      width: 100%;
      display: flex;
      margin: 3rem 0 1rem; }
      .Solutions .solutions-row div.solutions-contact-link-wrapper .solutions-contact {
        font-size: 1.2rem;
        margin: auto;
        padding: 1rem 2rem;
        letter-spacing: 1px; }
      .Solutions .solutions-row div.solutions-contact-link-wrapper .solutions-contact:hover {
        letter-spacing: 2px; }

.Slideshow {
  width: 100%;
  background-color: #eceaea;
  margin-bottom: 2rem;
  padding: 2rem; }
  .Slideshow .custom-carousel-caption {
    padding: 1rem !important;
    position: static !important; }
    .Slideshow .custom-carousel-caption h4 {
      color: #00111a;
      font-weight: 600;
      letter-spacing: 1px; }
    .Slideshow .custom-carousel-caption div p {
      margin: 1rem 0;
      color: #00111a; }
    .Slideshow .custom-carousel-caption div a {
      font-size: 1.2rem;
      text-decoration: none;
      color: #f57d3d; }
  .Slideshow .carousel-control-next:hover, .Slideshow .carousel-control-prev:hover {
    background-color: rgba(187, 183, 180, 0.75); }
  .Slideshow .custom-carousel-image {
    max-width: 100%;
    max-height: 450px;
    margin: auto;
    border: 1px solid lightgray; }
  .Slideshow .carousel-indicators {
    padding: 1rem; }

html {
  width: 100%;
  height: 100%;
  background-color: #ededed; }
  html body {
    height: 90%;
    background-color: transparent; }
    html body div#root {
      height: auto; }
      html body div#root .App {
        height: auto;
        font-size: 16px;
        font-family: 'Lato', sans-serif;
        display: flex;
        flex-direction: column;
        background-color: #ededed; }
    html body .hr-divider {
      background-color: #ff955c; }
    html body .light-gray-shadow {
      box-shadow: 0 2px 9px #cacaca; }
    html body .lg-light-gray-shadow {
      box-shadow: 0 5px 27px #d2d2d2; }
    html body .contact-link {
      background-color: #ff955c;
      margin: 1rem auto 0;
      padding: 1rem;
      width: 70%;
      color: white !important;
      text-align: center;
      letter-spacing: 1px;
      text-decoration: none; }
      @media (max-width: 992px) {
        html body .contact-link {
          margin: 1rem auto 2rem; } }
    html body .contact-link:hover {
      background-color: #ff7429; }

